class Search {

	/**
	 * Search Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - Search instance
	 * @param {object} utils - Utils instance
	 */

	constructor($, $el, utils) {
		const objects = {
			resultData: {},
			$body: $('body'),
			$form: $el.find('form'),
			$input: $el.find('.search-input'),
			$resultList: $el.find('.search-results'),
			$close: $el.find('[data-action="close"]'),
			$submit: $el.find('[data-action="submit"]'),
			$url: $el.find('form').data('url'),
			$cacheExpiration: $el.find('form').data('cache-expiration'),
			$maxResults: $el.find('form').data('max-results')
		};

		const api = objects.$url;

		let cacheInterval = '';
		const cacheExpiration = objects.$cacheExpiration || 5000; //client caching interval in milliseconds
		const maxResults = objects.$maxResults || 10;
		
		this.clearCache = () => {
			objects.resultData = {};
		};

		this.handleClose = () => {
			$el.removeClass('active');
			$('.search-backdrop').remove();
			$('.search').collapse('hide');
			$('#search-collapse').collapse('hide');
			objects.$resultList.hide();
			objects.$resultList.attr('aria-expanded', false);
		};

		this.handleSearch = (e) => {
			if (cacheInterval) clearInterval(cacheInterval);

			let query = $(e.currentTarget).val();

			// check for value already in cache
			if (query.length > 2 && !(query in objects.resultData)) {

				// query not in cache - need to fetch
				let client = `${api}${query}`;

				$.ajax({
					url: client,
					method: 'GET',
					async: true,
					beforeSend: function (xhr) {
						if (xhr && xhr.overrideMimeType) {
							xhr.overrideMimeType('application/json;charset=utf-8');
						}
					},
					dataType: 'json'
				})
					.done((res) => {
						// clear results
						if (objects.$resultList.length > 0) {
							objects.$resultList.empty();
						}

						objects.resultData[query] = res.suggestions;
						if (objects.resultData[query]) {
							let ariaResultCount = Math.min(maxResults, res.suggestions.length);
							objects.$resultList.append(`<li role="alert" class="sr-only">${ariaResultCount} suggested results</li>`);
							objects.resultData[query].map((obj,i) => {
								if ( i < maxResults) {
									let li = `<li><a title="${obj.value}" class="dropdown-item" href="${obj.href}">${obj.value}</li>`;
									objects.$resultList.append(li);
								}
							});
						}
					})
					.fail((err) => {
						//console.log('Error getting search results: ', err);
					})
					.always(() => {
						let viewAll = '<li><button type="submit" class="dropdown-item view-all-btn" role="link"><a>view all results</a></button></li>';
						objects.$resultList.append(viewAll);
						objects.$resultList.show();
						objects.$resultList.attr('aria-expanded', true);

						$('.search-backdrop').remove();
						$el.append('<div class="search-backdrop"></div>');

						// set timer to clear cache after each search performed
						cacheInterval = setTimeout(this.clearCache, cacheExpiration);
					});

			} else if (query in objects.resultData) {

				// clear results
				if (objects.$resultList.length > 0) {
					objects.$resultList.empty();
				}

				// query already performed with data in cache
				if (objects.resultData[query]) {
					objects.resultData[query].map((obj, i) => {
						if (i < maxResults) {
							let li = `<li><a title="${obj.value}" class="dropdown-item" href="${obj.href}">${obj.value}</li>`;
							objects.$resultList.append(li);
						}
					});
				}
				let viewAll = '<li><button type="submit" class="dropdown-item view-all-btn" role="link"><a>view all results</a></button></li>';
				objects.$resultList.append(viewAll);
				objects.$resultList.show();
				objects.$resultList.attr('aria-expanded', true);

				$('.search-backdrop').remove();
				$el.append('<div class="search-backdrop"></div>');

				// set timer to clear cache after each search performed
				cacheInterval = setTimeout(this.clearCache, cacheExpiration);

			} else {
				objects.$resultList.hide();
				objects.$resultList.attr('aria-expanded', false);
			}

		};

		this.firstRun = () => {
			console.info('~~~ Search Module ~~~');

			objects.$input.focus();
			objects.$close.on('click', this.handleClose);

			objects.$input.on('keyup', utils.debounce((e) => {
				this.handleSearch(e);
			}, 500));

			if (utils.getViewportSize() ==='lg' || utils.getViewportSize() ==='xl') {
				objects.$input.attr('placeholder', objects.$input.data('placeholder'));
			} else {
				objects.$input.attr('placeholder', objects.$input.data('placeholder-mobile'));
			}

			$('#search-collapse').on('shown.bs.collapse', () => {
				objects.$input.focus();
			});

			$el.on('shown.bs.collapse', () => {
				objects.$input.focus();
			});

			// The search module is displayed in the nav twice for large/desktop screens
			// and smaller screens. We need to add key event handlers for accessibility to
			// both instances.
			const searchModuleSelectors = [
				{ 
					moduleSelector: '.d-lg-block [data-module="Search"]',
					expandSelector: '.d-lg-block [data-target="#search-container"]'
				},
				{
					moduleSelector: '.search-toggle-container [data-module="Search"]',
					expandSelector: '.search-toggle-container [data-target="#search-collapse"]'
				}
				
			];

			searchModuleSelectors.forEach(({moduleSelector, expandSelector}) => {
				$(moduleSelector).on('keydown', function(e) {
					const searchContainer = document.querySelector(moduleSelector);
					const inputEl = $(moduleSelector).find('.search-input');
					const closeEl = $(moduleSelector).find('[data-action="close"]');
					const expandBtn = $(expandSelector);

					closeEl.on('click', () => {
						// Wait half a second so that the screen reader will announce the correct expand button
						// state on focus
						setTimeout(() => {
							expandBtn.focus();
						}, 500);
					});
				
					// Check if the active element is within the search container
					if (searchContainer.contains(document.activeElement)) {
						if (e.key === 'Escape' || e.keyCode === 27) {
							closeEl.click();
							e.stopPropagation();
							return;
						}
				
						const isTabPressed = e.key === 'Tab' || e.keyCode === 9;
						if (!isTabPressed) {
							return;
						}
				
						if (e.shiftKey) { // if shift key pressed for shift + tab combination
							if (document.activeElement === inputEl[0]) { // the input is the first "tab-able" element
								closeEl.focus();
								e.preventDefault();
							}
						} else { // if tab key is pressed
							if (document.activeElement === closeEl[0]) { // the close btn is the last "tab-able" element
								inputEl.focus();
								e.preventDefault();
							}
						}
					}
				});

			});		
		};
	}

	name() {
		return 'Search';
	}

	init() {
		this.firstRun();
	}

}

export default Search;
