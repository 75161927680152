import CustomEvent from 'custom-event';
import FavoriteButton from './FavoriteButton';

class Carousel {

	/**
	 * Carousel Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - Carousel instance
	 * @param {object} utils - Instance of Utils
	 */

	constructor($, $el, utils) {

		const defaults = {
			infinite: true,
			slidesToShow: 5,
			slidesToScroll: 1,
			slidesToScrollMobile: 1,
			centerMode: true,
			centerPadding: 0,
			variableWidth: false,
			focusOnSelect: false,
			focusOnChange: false,
			slidesToShowTabs: 7,
			slidesToScrollTabs: 1,
			slidesToShowMobile: 1,
			centerModeMobile: false,
			centerPaddingMobile: 0,
			variableWidthMobile: false,
			mobileOnlyCarousel: false,
			desktopOnlyCarousel: false,
			autoplay: false,
			autoplaySpeed: 5000,
			autoplayMobile: false,
			autoplaySpeedMobile: 5000,
			accessibility: true,
			fade: false
		};

		const params = {
			// Slick control variables (Desktop)
			infinite: $el.data('infinite') || defaults.infinite,
			mobileOnlyCarousel: $el.data('mobile-only') || defaults.mobileOnlyCarousel,
			desktopOnlyCarousel: $el.data('desktop-only') || defaults.desktopOnlyCarousel,
			slidesToShow: $el.data('slides-to-show') || defaults.slidesToShow,
			slidesToScroll: $el.data('slides-to-scroll') || defaults.slidesToScroll,
			slidesToScrollMobile: $el.data('slides-to-scroll-mobile') || defaults.slidesToScrollMobile,
			centerMode: $el.data('center-mode') != null ? $el.data('center-mode') : defaults.centerMode,
			centerPadding: $el.data('center-padding') || defaults.centerPadding,
			variableWidth: $el.data('variable-width') || defaults.variableWidth,
			focusOnSelect: $el.data('focus-on-select') || defaults.focusOnSelect,
			focusOnChange: $el.data('focus-on-change') || defaults.focusOnChange,
			fade: $el.data('fade') || defaults.fade,

			// autoplay settings
			autoplay: $el.data('auto-play') || defaults.autoplay,
			autoplaySpeed: $el.data('auto-play-speed') || defaults.autoplaySpeed,
			autoplayMobile: $el.data('auto-play-mobile') || defaults.autoplay,
			autoplaySpeedMobile: $el.data('auto-play-speed-mobile') || defaults.autoplaySpeed,

			// Slick control variables (Mobile)
			slidesToShowMobile: $el.data('slides-to-show-mobile') || defaults.slidesToShowMobile,
			centerModeMobile: $el.data('center-mode-mobile') || defaults.centerModeMobile,
			centerPaddingMobile: $el.data('center-padding-mobile') || defaults.centerPaddingMobile,
			variableWidthMobile: $el.data('variable-width-mobile') || defaults.variableWidthMobile,

			// Slick Tabbed Nav controls
			slidesToShowTabs: $el.find('.carousel-tabs').data('slides-to-show-tabs') || defaults.slidesToShowTabs,
			slidesToScrollTabs: $el.find('.carousel-tabs').data('slides-to-scroll-tabs') || defaults.slidesToScrollTabs,
		};

		const objects = {
			$carousel: $el.find('.carousel'),
			$carouselNav: $el.find('.carousel-tabs'),
			$carouselNavItems: $el.find('.carousel-tabs a'),
			$btnPrev: $el.find('[data-prev]'),
			$btnNext: $el.find('[data-next]'),
			$indicators: $el.find('.carousel-indicators'),
			$linkedMap: $('[data-linked-map]'),
			$slides: $el.find('.slick-slide')
		};

		//* Per https://projects.avantia.net/issues/24747
		//TODO: possibly break the follwing into its own file  */
		$('.destination-learn-more-button').on('focus', function() {
			$(this).attr('aria-selected', true);
		});
	
		$('.destination-learn-more-button').on('focusout', function() {
			$(this).attr('aria-selected', false);
		});
		//*

		// let utils = new Utils();

		/**
		 * destroyCarousel()
		 * Deactivates Slick Carousel
		 */
		this.destroyCarousel = (
			$carousel = objects.$carousel
		) => {
			$carousel.slick('unslick');
		};

		/**
		 * initCarousel()
		 * Activates Slick Carousel
		 */
		this.initCarousel = (
			$carousel = objects.$carousel,
			$carouselNav = objects.$carouselNav,
			options = {
				slickOptions: {
					infinite: params.infinite,
					slidesToShow: params.slidesToShow,
					slidesToScroll: params.slidesToScroll,
					autoplay: params.autoplay,
					autoplaySpeed: params.autoplaySpeed,
					arrows: true,
					fade: params.fade,
					dots: true,
					centerMode: params.centerMode,
					centerPadding: params.centerPadding,
					prevArrow: objects.$btnPrev,
					nextArrow: objects.$btnNext,
					appendDots: objects.$indicators,
					variableWidth: params.variableWidth,
					speed: 700,
					cssEase: 'ease-in-out',
					focusOnSelect: params.focusOnSelect,
					focusOnChange: params.focusOnChange,
					accessibility: true,

					responsive: [{
						breakpoint: utils.constants.size.medium,

						settings: {
							infinite: params.infinite,
							slidesToShow: params.slidesToShowMobile,
							slidesToScroll: params.slidesToScrollMobile,
							centerMode: params.centerModeMobile,
							centerPadding: params.centerPaddingMobile,
							variableWidth: params.variableWidthMobile,
							autoplay: params.autoplayMobile,
							autoplaySpeed: params.autoplaySpeedMobile,
							speed: 500
						}
					}]
				},
				slickNavOptions: {
					infinite: defaults.infinite,
					slidesToShow: params.slidesToShowTabs,
					slidesToScroll: params.slidesToScrollTabs,
					arrows: false,
					dots: false,
					focusOnSelect: true,
					variableWidth: true,
					centerMode: true
				}
			}) => {

			// first set options for carousel / nav
			if ($carousel.length > 0) {
				options.slickNavOptions.asNavFor = $carousel;
			}
			if ($carouselNav && $carouselNav.length > 0 && $carouselNav.children().length > 0) {
				options.slickOptions.asNavFor = $carouselNav;

				if (options.slickNavOptions.slidesToShow >= $carouselNav.children().length) {
					options.slickNavOptions.slidesToShow = 7;
				}
			}

			if($el.data('center-mode-active')) {
				if($carousel.children().length > options.slickOptions.slidesToShow) {
					options.slickOptions.centerMode = true;
				} else {
					options.slickOptions.centerMode = false;
				}
			}

			// start: additional carousel syncing
			///////////////////////////////////////

			let controllerCarouselId = this.id;
			if (controllerCarouselId && $el.data('sync')) {
				if ($(`[data-sync="#${controllerCarouselId}"]`).length > 0) {
					//there are carousels to be synced - set controller asNavFor to subscribed carousels
					options.slickOptions.asNavFor = $(`[data-sync="#${controllerCarouselId}"] .carousel`);
				}
			}

			if ($el.data('sync')) {
				// if initializing subscribing carousels - set asNavFor to controller carousel
				options.slickOptions.asNavFor = $($el.data('sync')).find('.carousel');
			}

			if ($el.data('sync-map')) {
				let $linkedMap = $(`[data-sync-carousel="#${controllerCarouselId}"]`);
				$linkedMap.on('markerclick', (e) => {
					let slide = this.getSlide('data-id', e.detail.marker.id);

					if (slide < 0) {
						slide = this.getSlide('data-property-id', e.detail.marker.id);
					}

					if (slide >= 0) {
						this.showSlide(slide);
					}
				});
			}

			//set active slide based on url parameter
			if ($el.data('url-param')) {
				let paramKey = $el.data('url-param');
				let paramValue = utils.getUrlParameter(paramKey);

				$(window).on('load', () => {
					if (paramValue) {
						let slide = this.getSlide('data-id', paramValue);
						this.showSlide(slide);
					} else {
						this.showSlide(0);
					}
				});
			}

			///////////////////////////////////////

			// then initiate carousel object
			let viewport = utils.getViewportSize();
			let slidesToShow = options.slickOptions.slidesToShow;
			if (viewport === 'xs' || viewport === 'sm') {
				slidesToShow = options.slickOptions.responsive[0].settings.slidesToShow;
			}

			if($el.data('load-first')) {
				if(!$carousel.hasClass('slick-initialized')) {
					$carousel.slick(options.slickOptions);
				}
			}

			if (!$carousel.hasClass('slick-initialized')) {
				// carousel needs to be initialized
				$carousel.slick(options.slickOptions);
			} else {
				// carousel already initialized
			}

			objects.$slides = $el.find('.slick-slide:not(.slick-cloned)');

			if (objects.$slides.length > slidesToShow) {
				objects.$btnPrev.show();
				objects.$btnNext.show();
				objects.$indicators.show();
			} else {
				objects.$btnPrev.hide();
				objects.$btnNext.hide();
				objects.$indicators.hide();
			}

			if ($carouselNav && $carouselNav.length > 0 && $carouselNav.children().length > 0) {
				if ($carouselNav.children().length > 1) {
					if ($carouselNav.children().length <= 7) {
						options.slickNavOptions.centerMode = false;
						options.slickNavOptions.variableWidth = false;
					}
					$carouselNav.slick(options.slickNavOptions);
				}
			}

			$el.find('.slick-track').each(function(index) {
				const carouselTitle = $el.find("h1.headline-2").text();
				$(this).attr('aria-label', "Tabs for " + carouselTitle);
				$(this).attr('role', 'tablist');
			});

			const tabPanels = $el.find('.slick-slide[role="tabpanel"]:not(.slick-cloned)');
			// Make sure all the tabpanels have tabindex="-1" to start
			tabPanels.each(function() {
				$(this).attr('tabindex', '-1');
			});

			// Listen for aria-selected value being added/removed to these elements, and set
			// tabindex attr accordingly
			const slickSlideEls = $el.find('.slick-slide');
			slickSlideEls.each(function(index) {
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						const ariaSelectedValue = $(mutation.target).attr('aria-selected');
						const tabindexValue = ariaSelectedValue == 'true' ? '0' : '-1';
						$(mutation.target).attr('tabindex', tabindexValue);
					});
				});

				observer.observe(slickSlideEls[index], {
					attributes: true,
					attributeFilter: ['aria-selected']
				});
			});

			const carouselTabsSelector = 'button.slick-slide';
			const tabs = $el.find(carouselTabsSelector);

			$el.find(carouselTabsSelector).each(function(index) {
				const selected = $(this).hasClass('slick-current');
				const tabPanelId = $(this).attr('aria-controls');
				const tabPanelEl = $el.find('#' + tabPanelId);
				const lastTab = index == tabs.length - 1;
				const firstTab = index == 0;
				const id = $(this).attr("id");
				const tabIdNum = parseInt(id.replace("tab-", ""));

				$(this).attr('aria-selected', selected ? 'true' : 'false');
				$(this).attr('tabindex', firstTab ? '0' : '-1');
				tabPanelEl.attr('tabindex', firstTab ? '0' : '-1');

				$(this).on('keydown', (e) => {
					if (e.key == 'ArrowRight') {
						if (lastTab) {
							$el.find('#tab-1').focus();
						} else {
							$el.find('#tab-' + (tabIdNum + 1)).focus();
						}
					} else if (e.key == 'ArrowLeft') {
						if (firstTab) {
							$el.find('#tab-' + (tabs.length)).focus();
						} else {
							$el.find('#tab-' + (tabIdNum - 1)).focus();
						}
					}
				});
			});

			// Mobile Only Carousel
			const handleMobileOnly = () => {
				let viewport = utils.getViewportSize();

				if (viewport === 'xs' || viewport === 'sm') {
					if (!$carousel.hasClass('slick-initialized')) {
						$carousel.slick(options.slickOptions);
					}
				} else {
					if ($carousel.hasClass('slick-initialized')) {
						$carousel.slick('unslick');
					}

					objects.$carousel.children().find('a').attr('tabindex', 0);
					objects.$carousel.children().find('button').attr('tabindex', 0);
				}
			};

			if (params.mobileOnlyCarousel === true) {
				handleMobileOnly();

				$(window).on('resize orientationchange', function() {
					handleMobileOnly();
				});
			}

			const handleDesktopOnly = () => {
				let viewport = utils.getViewportSize();

				if (viewport === 'xs' || viewport === 'sm') {
					if ($carousel.hasClass('slick-initialized')) {
						$carousel.slick('unslick');
					}
				} else {
					if (!$carousel.hasClass('slick-initialized')) {
						$carousel.slick(options.slickOptions);
					}
				}
			};

			if (params.desktopOnlyCarousel === true) {
				handleDesktopOnly();

				$(window).on('resize orientationchange', function() {
					handleDesktopOnly();
				});
			}

			//Init cloned FavoriteButtons
			$('.slick-cloned').find('.btn-favorite').each(function() {
				let module = new FavoriteButton($, $(this), utils);
				if ($(this).data('init') !== false) {
					module.init();
				}
			});

			// Avoid covering captions with buttons.
			const $buttons = $el.find('[data-next], [data-prev]');
			if (window.innerWidth <= 768) {
				let offset = 0;
				objects.$slides.each(function () {
					if (this.nodeName === 'FIGURE') {
						const $this = $(this);
						let figHeight = $this.outerHeight();
						let captionHeight = $(this).children('figCaption').outerHeight();
						const slideOffset = Math.round((figHeight - captionHeight) / 2);
						offset = offset ? Math.min(offset, slideOffset) : slideOffset;
						$buttons.css('top',`${offset}px`, 'important');
					}
				});
			} else {
				$buttons.css('top','');
			}
		};

		/**
		 * showSlide()
		 * Changes carousel to show specific slide
		 * @param {string} slide - Index of slide to show
		 */
		this.showSlide = (slide) => {
			if (slide !== null) {
				objects.$carousel.slick('slickGoTo', slide);
			}
		};

		/**
		 * getSlide()
		 * Returns specific slide index within collection of slides
		 * @param {string} key - Attribute key to find slide by. i.e. 'data-id'
		 * @param {string} value - Value of attribute to find slide by
		 * @returns {number} - Index within collection of slides
		 */
		this.getSlide = (key, value) => {
			let $slide = objects.$slides.filter(function() {
				return $(this).attr(key) === value;
			});
			if ($slide) {
				let index = objects.$slides.index($slide);
				return index;
			}
			return null;
		};

		this.id = $el.attr('id');

		this.resize = () => {
			this.initCarousel();
		};

		this.firstRun = () => {
			// check for IE to fix SVG rendering bug
			if (document.documentMode) {
				objects.$carousel.on('init', (e) => {
					objects.$carousel.find('use').each(function() {
						let $use = $(this).get(0);
						if ($use.href && $use.href.baseVal) {
							// eslint-disable-next-line no-self-assign
							$use.href.baseVal = $use.href.baseVal; // trigger fixing of href
						}
					});
				});
			}

			this.initCarousel();

			objects.$carouselNavItems.on('click', (e) => {
				e.preventDefault();
			});

			// change of carousel slide will dispatch event containing data attributes for any listeners
			objects.$carousel.on('afterChange', (event, slick, currentSlide) => {
				let data = $(slick.$slides[currentSlide]).data();
				let ev = new CustomEvent('slidechange', { 'detail': { data: data }} );
				$el[0].dispatchEvent(ev);

				// Resize Carousel
				var maxHeight = Math.max.apply(null, $('.slick-active').map(function () {
					return $(this).height();
				}).get());
				$('#property-carousel-1').animate({height: maxHeight}, 50).css({ overflow:'visible'});

				// Collect GTM attributes for dataLayer push
				window.dataLayer.push({
					'event': 'slideChange',
					'data-gtm-module': data['gtmModule'],
					'data-gtm-view': data['gtmView'],
					'data-gtm-id': data['gtmId'],
					'data-gtm-position': data['gtmPosition']
				});

				$el.find('.slick-slide').each(function(index) {
					const selected = $(this).hasClass('slick-current');
					$(this).attr('aria-selected', selected ? 'true' : 'false');
				});
			});

			objects.$carousel.on(
				'beforeChange',
				function (event, slick, currentSlide, nextSlide) {

					//* Pause YouTube or embedded video file when carousel scrolls past it
					//* Video player code is within the feature and property feature components
					var vid = document.getElementById('video-plyr');

					if (vid) {
						if ($(vid).parents('.carousel').length) {
							vid.pause();
						}
					}

					if (window.player) {
						if ($('#youtube-video-plyr').parents('.carousel').length) {
							window.player.pauseVideo();
						}
					}
				}
			);

			$(window).on('load', function(){
				// Resize Carousel on page load
				var maxHeight = Math.max.apply(null, $('.slick-active').map(function () {
					return $(this).height();
				}).get());
				$('#property-carousel-1').animate({height: maxHeight}, 50).css({ overflow:'visible'});
			});
			$( window ).on('resize', function() {
				// Resize Carousel on window resize
				var maxHeight = Math.max.apply(null, $('.slick-active').map(function () {
					return $(this).height();
				}).get());
				$('#property-carousel-1').animate({height: maxHeight}, 50).css({ overflow:'visible'});
			});
		};
	}

	name() {
		return 'Carousel';
	}

	init() {
		this.firstRun();
	}
}

export default Carousel;
