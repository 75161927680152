import Carousel from '../modules/Carousel';
import moment from 'moment';

class RoomDetail {

	/**
	 * RoomDetail Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - RoomDetail instance
	 * @param {object} utils - Utils instance
	 * @param {object} rateApi - RateApi instance
	 */

	constructor($, $el, Utils, rateApi) {

		let qs = new URLSearchParams(window.location.search);

		const objects = {
			numberFormat: Intl.NumberFormat(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2}),
			numberFormatNoDecimal: Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits:0}),
			propertyId: $el.data('property-id'),
			rateUrl: $el.data('rate-url'),
			roomType: $el.data('room-type'),
			$available: $el.find('.js-available'),
			$availableHide: $el.find('.js-available-hide'),
			$availableShow: $el.find('.js-available-show'),
			$checkRates: $el.find('.js-check-rates'),
			$rate: $el.find('.js-room-rate'),
			$carousel: $el.find('.carousel-factory[data-init="false"]'),
			startDate: qs.get('start-date'),
			endDate: qs.get('end-date'),			
			objCarousel: null
		};

		this.firstRun = () => {
			console.info('~~~ RoomDetail Module ~~~');

			$(document).on('reservationdatesselected', this.handleDateChange);

			$(document).on('room-rates-loaded', this.handleRateLoad);

			this.initPrice();

			this.initializeFootnoteClicks();

			// On ajax complete, initialize carousel
			$(document).on('ajaxStop', this.initializeCarousel);
		};

		this.initializeFootnoteClicks = () => {
			document.addEventListener("click", function (e) {
				const footnoteReturnId = e.target.dataset.footnoteReturnId;
				const id = e.target.id;

				// When link to footnote is clicked, set the back link to the property ID and room type of the listing
				// where click happened
				if (footnoteReturnId) {
					$("#footnotes-back-link").attr("href", footnoteReturnId);
					setTimeout(() => {
						$("#footnotes-back-link").focus();
					}, 500);
				}

				// When the footnote back link is clicked, wait until link has been followed and then 
				// revert the back link href to a null value
				if (id == "footnotes-back-link") {
					setTimeout(() => {
						$("#footnotes-back-link").attr("href", '#');
						$('[data-footnote-return-id="#price-listing"]').focus(); // refocus on asterisk link
					}, 500);
				}
			});
		};

		this.initializeCarousel = () => {
			objects.objCarousel = new Carousel($, objects.$carousel, Utils);
			objects.objCarousel.init();
		};

		this.handleDateChange = (event) => {
			const startDate = moment(event.detail.startDate).format('YYYY-MM-DD');
			const endDate = moment(event.detail.endDate).format('YYYY-MM-DD');
			// this.updatePrice(startDate, endDate);
			rateApi.requestData(startDate, endDate);
		};

		this.handleRateLoad = (event) => {
			console.log('RD = Handle Rate Load');
			if(typeof event.detail.data === 'undefined') {
				console.log('Event Data Undefined');
				return;
			}
			let hotels = event.detail.data.hotels;
			// console.log("Rate Load: "+JSON.stringify(hotels));
			console.log('RD = Matching P: '+objects.propertyId+' R: '+objects.roomType);

			let found = false;
			for (let i = 0; i < hotels.length; ++i) {
				let hotel = hotels[i];
				if(parseInt(hotel.hotelId) === parseInt(objects.propertyId)) {
					console.log('RD: Hotel Matches '+hotel.hotelId);
					let rooms = hotel.rooms;
					for (let j = 0; j < rooms.length; j++) {
						let room = rooms[j];
						// console.log("Matching Room: " + objects.roomType);
						// console.log("My Room: "+room.roomId);
						// console.log("Match: "+(room.roomId === objects.roomType));
						if(room.roomId === objects.roomType) {
							found = true;
							console.log('Room Match! Price: '+room.ABT);

							let price = room.ABT;
							objects.$rate = $el.find('.js-room-rate');

							if (room.status === 'available') {
								objects.$rate.text('$' + objects.numberFormatNoDecimal.format(Math.ceil(price)));
								objects.$available.removeClass('sold-out');
								objects.$availableHide.hide();
								objects.$availableShow.show();
								if(objects.$availableShow.attr('aria-hidden')){
									objects.$availableShow.attr('aria-hidden', false);
								} 
							} else {
								objects.$rate.text('');
								objects.$available.addClass('sold-out');
								objects.$availableHide.show();
								objects.$availableShow.hide();
								objects.$checkRates.hide();
								if(objects.$availableShow.attr('aria-hidden')){
									objects.$availableShow.attr('aria-hidden', true);
								} 
							}

						}
					}
				}
			}
			if(!found) {
				// No data, assume sold out
				objects.$rate.text('');
				objects.$available.addClass('sold-out');
				objects.$availableHide.show();
				objects.$availableShow.hide();
				objects.$checkRates.hide();
				if(objects.$availableShow.attr('aria-hidden')){
					objects.$availableShow.attr('aria-hidden', true);
				} 
			}

		};

		// this.handleNewData = (result) => {
		// 	let price = result.data.dailyRate;
		// 	objects.$rate = $el.find('.js-room-rate');
		//
		// 	if (result.data.status === 'available') {
		// 		objects.$rate.text('$' + objects.numberFormatNoDecimal.format(Math.ceil(price)));
		// 		objects.$available.removeClass('sold-out');
		// 		objects.$availableHide.hide();
		// 		objects.$availableShow.show();
		// 	} else {
		// 		objects.$rate.text('');
		// 		objects.$available.addClass('sold-out');
		// 		objects.$availableHide.show();
		// 		objects.$availableShow.hide();
		// 	}
		// };

		this.initPrice = () => {
			// Check date from parameters
			let paramStartDate = moment(objects.startDate);
			let paramEndDate = moment(objects.endDate);
			if (paramStartDate.isValid() && paramEndDate.isValid()) {
				rateApi.requestData(paramStartDate.format('YYYY-MM-DD'), paramEndDate.format('YYYY-MM-DD'));
			} else {
				// If dates are set, call updatePrice
				const startDate = moment(localStorage.getItem('startDate'));
				const endDate = moment(localStorage.getItem('endDate'));
				if (startDate.isValid() && endDate.isValid()) {
					// this.updatePrice(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));

					rateApi.requestData(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
				}
			}
		};

		this.resize = () => {
		};

		// this.updatePrice = (startDate, endDate) => {
		// 	console.debug(`${objects.propertyId}:${startDate}:${endDate}:${objects.roomType}`);
		// 	const url = `${objects.rateUrl}?propertyId=${objects.propertyId}&startDate=${startDate}&endDate=${endDate}&roomType=${objects.roomType}`;
		// 	$.ajax({
		// 		type: 'GET',
		// 		url: url,
		// 		dataType: 'json'
		// 	})
		// 		.done(this.handleNewData)
		// 		.fail((jqXHR, textStatus, errorThrown) => {
		// 			console.log(`Error searching for property: ${textStatus}, ${errorThrown}`);
		// 		});
		// };
	}

	// noinspection JSMethodCanBeStatic
	name() {
		return 'RoomDetail';
	}

	init() {
		this.firstRun();
	}

}

export default RoomDetail;
